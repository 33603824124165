import React from "react";

import './footer.css';

const Footer = () => {
  return (
    <div className="appfooter">
      <span>No podemos garantizar la aprobación de una visa, debido a que es una decisión de las autoridades americanas, en la cual no tenemos injerencia alguna. Nos regimos siempre bajo los estatus que indica las autoridades consulares. En caso de rechazo de visa, no hay rembolso de la cuota de visa, ni de la gestoría.</span>
    </div>
  );
};

export default Footer;