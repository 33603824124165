import React from "react";
import { CCarousel, CCarouselItem, CImage, CCarouselCaption } from '@coreui/react'
import '@coreui/coreui/dist/css/coreui.min.css'
import "bootstrap/dist/css/bootstrap.min.css";
import './inicio.css';


const Inicio = () => {
  return (
    <CCarousel controls indicators transition="crossfade">
      <CCarouselItem>
        <CImage fluid rounded className="d-block w-100" src="./images/inicio1.jpg" alt="slide 1" />
        <CCarouselCaption className="d-none d-md-block">
          <div className="textosCarrusel">
            <h5>Te ayudamos con el tr&aacute;mite de tu visa</h5>
            <p>Perm&iacute;tenos iniciar esta aventura contigo.</p>
          </div>
        </CCarouselCaption>
      </CCarouselItem>
      <CCarouselItem>
        <CImage fluid rounded className="d-block w-100" src="./images/inicio2.jpg" alt="slide 2" />
        <CCarouselCaption className="d-none d-md-block">
          <div className="textosCarrusel">
            <h5>Atenci&oacute;n personalizada</h5>
            <p>Estaremos contigo en cada paso, porque aqu&iacute; es cuando importa el trato personal.</p>
          </div>
        </CCarouselCaption>
      </CCarouselItem>
      <CCarouselItem>
        <CImage fluid rounded className="d-block w-100" src="./images/inicio3.jpg" alt="slide 3" />
        <CCarouselCaption className="d-none d-md-block">
          <div className="textosCarrusel">
            <h5>Empieza tu aventura hoy</h5>
            <p>Nosotros te decimos como.</p>
          </div>
        </CCarouselCaption>
      </CCarouselItem>
      <CCarouselItem>
        <CImage fluid rounded className="d-block w-100" src="./images/inicio4.jpg" alt="slide 3" />
        <CCarouselCaption className="d-none d-md-block">
          <div className="textosCarrusel">
            <h5>La vida es un viaje</h5>
            <p>Te ayudamos a comenzarlo.</p>
          </div>
        </CCarouselCaption>
      </CCarouselItem>
    </CCarousel>
  );
};

export default Inicio;