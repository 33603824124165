import React from "react";

import './servicios.css';

const Us = () => {
  return (
    <section>
      <div class="row">
        <h2 class="section-heading">Nuestros Servicios</h2>
      </div>
      <div class="row">
        <div class="column">
          <div class="card">
            <div class="icon-wrapper">
              <i class="fas fa-hammer"></i>
            </div>
            <h3>Asesor&iacute;a</h3>
            <p>
            Recomendamos el tipo de visa que m&aacute;s le conviene (turista, negocios, empleada dom&eacute;stica, TN, etc.)
            </p>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="icon-wrapper">
              <i class="fas fa-brush"></i>
            </div>
            <h3>Llenado de formularios</h3>
            <p>
            Recabamos la informaci&oacute;n completa para el llenado de la solicitud (formato en l&iacute;nea, correo y/o vía telef&oacute;nica)
            </p>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="icon-wrapper">
              <i class="fas fa-wrench"></i>
            </div>
            <h3>Control de tu informaci&oacute;n</h3>
            <p>
            Creamos una cuenta para control de su informaci&oacute;n.
            </p>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="icon-wrapper">
              <i class="fas fa-truck-pickup"></i>
            </div>
            <h3>Tu formato de pago</h3>
            <p>
            Enviamos el formato de pago (el pago lo realiza usted directo a las cuentas embajada)
            </p>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="icon-wrapper">
              <i class="fas fa-broom"></i>
            </div>
            <h3>Programamos tu entrevista</h3>
            <p>
            Seleccionamos lugar y fecha de su entrevista, monitoreamos disponibilidad de fechas más cercanas
            </p>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="icon-wrapper">
              <i class="fas fa-plug"></i>
            </div>
            <h3>siempre contigo</h3>
            <p>
            Damos el seguimiento hasta que tenga su visa en mano (en caso de que fuera aprobada)
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Us;