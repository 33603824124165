import React, { Component } from 'react';
import Carousel from 'react-elastic-carousel';
import './recomendaciones.css';

const Recomendaciones = () => {
    return (
        <div className='recomendaciones'>
            <Carousel verticalMode itemsToShow={1}>
                <img src="./images/recomendaciones1.png" />
                <img src="./images/recomendaciones2.png" />
            </Carousel>
        </div>
    );
};

export default Recomendaciones;