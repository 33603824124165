import React from "react";
import { CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/bootstrap-react'

const Faq = () => {
  return (
    <CAccordion activeItemKey={1}>
      <CAccordionItem itemKey={1}>
        <CAccordionHeader>
          ¿Cu&aacute;ntos tipos de visas existen y cual es la adecuada para mi viaje?
        </CAccordionHeader>
        <CAccordionBody>
          <strong>Para una visa tanto de turismo como de empleo, se requiere la siguiente información</strong>
          <p />
          <ul>
            <li>Pasaporte con vigencia m&iacute;nima de 6 meses</li>
            <li>Formulario con información personal (DS160) </li>
            <li>Cuota de pago de visa (160 usd al tipo de cambio)</li>
            <li>Agendar la y/o citas correspondientes</li>
            <li>En caso de menores de edad y/o adultos mayores fotograf&iacute;a infantil.</li>
          </ul>
        </CAccordionBody>
      </CAccordionItem>
      <CAccordionItem itemKey={2}>
        <CAccordionHeader>
          Qu&eacute; costo tiene una visa para los Estados Unidos de Am&eacute;rica?
        </CAccordionHeader>
        <CAccordionBody>
          <strong>$160 USD</strong>
          <br />
          <ul>
            <li>(B) Visitante: Negocios, Turismo, Tratamiento m&eacute;dico</li>
            <li>(C) Tr&aacute;nsito en los EE. UU.</li>
            <li>(D) Miembro de tripulación</li>
            <li>(F) Estudiante acad&eacute;mico</li>
            <li>(M) Estudiante no acad&eacute;mico/t&eacute;cnico</li>
            <li>(I) Medios o periodistas</li>
            <li>(J) Visitante de intercambio</li>
            <li>(TD/TN) Profesional del TLC</li>
            <li>(T) V&iacute;ctima de trata de personas</li>
            <li>(U) V&iacute;ctima de actividad delictiva</li>
          </ul>
          <p />
          <strong>$190 USD</strong>
          <br />
          <ul>
            <li>(H) Trabajador/empleo temporal o pasantes</li>
            <li>(O) Personas con capacidades extraordinarias</li>
            <li>(P) Atletas, artistas y animadores</li>
            <li>(Q) Visitante de intercambio cultural internacional</li>
            <li>(R) Trabajador religioso</li>
            <li>(L) Empleado transferido dentro de su empresa</li>
          </ul>
          <p />
          <strong>$15 USD</strong>
          <br />
          <ul>
            <li>
              Pago para menor de 15 años solicitando visa de Visitante (B1/B2)
            </li>
          </ul>
        </CAccordionBody>
      </CAccordionItem>
      <CAccordionItem itemKey={3}>
        <CAccordionHeader>
          Cu&aacute;les son los t&eacute;rminos y condiciones de los cargos de solicitud de visa
        </CAccordionHeader>
        <CAccordionBody>
          <strong>Los pagos de la cuota de solicitud de visa estadounidense:</strong>
          <br />
          <ul>
            <li>No son reembolsables </li>
            <li>No son transferibles.</li>
            <li>Son v&aacute;lidos para una sola solicitud.</li>
            <li>Vencen despu&eacute;s de 1 año - </li>
            <li>Los pagos por visa estadounidense efectuados para que se apliquen en un pa&iacute;s espec&iacute;fico no pueden transferirse a otro pa&iacute;s</li>
          </ul>
        </CAccordionBody>
      </CAccordionItem>
      <CAccordionItem itemKey={4}>
        <CAccordionHeader>
          Cu&aacute;nto tiempo tardan en entregarme una visa, si es autorizada?
        </CAccordionHeader>
        <CAccordionBody>
          <ul>
            <li>Normalmente tardan de 4 a 8 semanas, en ocasiones pueden demorar hasta 6 meses si se va a proceso administrativo, este proceso es una revisión m&aacute;s exhaustiva de su información</li>
          </ul>
        </CAccordionBody>
      </CAccordionItem>
      <CAccordionItem itemKey={5}>
        <CAccordionHeader>
          Cómo me entregan mi visa en caso de ser autorizada?
        </CAccordionHeader>
        <CAccordionBody>
          <ul>
            <li>En caso de ser autorizada, los servicios consulares americanos la env&iacute;an por medio de DHL, no con todos tienen convenio, por lo que se elige el m&aacute;s cercano a su domicilio u oficina, en caso de no haber alguno, la entrega en su CAS.</li>
          </ul>
        </CAccordionBody>
      </CAccordionItem>
      <CAccordionItem itemKey={6}>
        <CAccordionHeader>
          Puedo realizar el tr&aacute;mite en cualquier consulado o ciudad?
        </CAccordionHeader>
        <CAccordionBody>
          <ul>
            <li>Si en cualquier consulado dentro de la rep&uacute;blica mexicana.</li>
          </ul>
        </CAccordionBody>
      </CAccordionItem>
      <CAccordionItem itemKey={7}>
        <CAccordionHeader>
          Por cu&aacute;nto tiempo me autorizan una visa americana?
        </CAccordionHeader>
        <CAccordionBody>
          <ul>
            <li>En caso de las visas para turista es de 10 años, en caso de menores de edad es por 10 años o hasta que cumpla los 15 años, lo que suceda primero
              En caso de visas de trabajo, pueden ir desde 1 hasta 3 años</li>
          </ul>
        </CAccordionBody>
      </CAccordionItem>
    </CAccordion>
  );
};

export default Faq;