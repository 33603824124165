import React from "react";

import 'bootstrap/dist/css/bootstrap.min.css'
import './us.css';

const Us = () => {
  return (
    <div class="responsive-container-block big-container">
      <div class="responsive-container-block container">
        <div class="responsive-container-block">
          <div class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-6 wk-ipadp-12 img-one">
            <img alt="golden-lines.png" class="image-block bg-image" src="./images/goldenLines.png" />
          </div>
          <div class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-6 wk-ipadp-12 content-one">
            <p class="text-blk section-head">
              Somos un equipo que brinda un servicio de gestor&iacute;a y asesoramiento sobre los diferentes tipos de visas que existen.
            </p>
            <p class="text-blk section-text">
              Como todos sabemos, obtener una visa para viajar a Estados Unidos u otros pa&iacute;ses puede ser un proceso complicado y frustrante; especialmente si no se tiene conocimiento, si no cuenta con el tiempo necesario y/o sencillamente prefiere realizar el tr&aacute;mite con asesores que cuentan con experiencia necesaria.
            </p>
            <p class="text-blk section-text">
              Es por eso que decidimos crear un servicio para ayudar de forma completa en el proceso del tr&aacute;mite.
              Si est&aacute; interesado en nuestros servicios, no dude en contactarnos…
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Us;