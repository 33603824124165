import React, { useState } from 'react'

import Inicio from './inicio.js';
import Us from './us.js';
import Servicios from './servicios.js';
import Contacto from './contacto.js';
import Faq from './faq.js';
import Recomendaciones from './recomendaciones.js';
import Header from './header.js';
import Footer from './footer.js';

import 'bootstrap/dist/css/bootstrap.css';
import './app.css';

const App = () => {
  const [option, setOption] = useState(<Inicio />)

  const sendData = (option) => {
    console.log("Option=" + option);

    switch (option) {
      case 'faq':
        setOption(<Faq />)
        break
      case 'us':
        setOption(<Us />)
        break
      case 'servicios':
        setOption(<Servicios />)
        break
      case 'contacto':
        setOption(<Contacto />)
        break
      case 'recomendaciones':
        setOption(<Recomendaciones />)
        break
      default:
        setOption(<Inicio />)
        break
    }
  }

  return (
    <div className='wrapper'>
      <div className="header">
        <Header sendData={sendData} />
      </div>
      <div id="content" className="content">
        {option}
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default App;