import React from "react";

import './contacto.css';

const Us = () => {
  return (
    <div className="contacto">
      <div className="contactoWrapper">
        <div className="contactoItem">
          <img src="./images/telefono.png" />
          <br />
          <span>&nbsp;&nbsp;&nbsp;&nbsp;Ll&aacute;manos</span>
          <br />
          <font>&nbsp;&nbsp;&nbsp;&nbsp;55-37-57-32-67</font>
        </div>

        <div className="contactoItem">
          <img src="./images/email.png" />
          <br />
          <span>Escr&iacute;benos</span>
          <br />
          <font>ventasvisaseasy@gmail.com</font>
        </div>

        <div className="contactoItem">
            <a href="https://wa.me/%2B5636289090?text=Hola%20quisiera%20pedir%20informes" target="_blank" rel="noopener noreferrer">
            <img id="imgWhatsApp" src="./images/whats.png" />
            <br />
            Contactanos
            <br />
            <font>55-37-57-32-67</font>
        </a>
        </div>
        
      </div>
      Horario de atenci&oacute;n: de lunes a viernes de 10 a 17 hrs
    </div>
  );
};

export default Us;