import React, { useState } from 'react'
import { bubble as Menu } from 'react-burger-menu'
import './header.css';

const Header = (props) => {
    const [menuOpen, setMenuOpen] = useState(false)

    const onMenuClick = (e) => {
        setMenuOpen(false)
        props.sendData(e.target.id)
    }

    const handleOnOpen = (state) => {
        console.log("handleOnOpen=" + state)
        setMenuOpen(true)
        
      }
    
      const handleOnClose = (state) => {
        console.log("handleOnClose=" + state)
        setMenuOpen(false)
      }

    return (
        <div className="headerStyle">
            <Menu isOpen={menuOpen} onOpen={ handleOnOpen } onClose={ handleOnClose }>
                <div id="inicio" className="menu-item" onClick={onMenuClick}>Inicio</div>
                <div id="us" className="menu-item" onClick={onMenuClick}>Quienes somos?</div>
                <div id="servicios" className="menu-item" onClick={onMenuClick}>Servicios</div>
                <div id="contacto" className="menu-item" onClick={onMenuClick}>Contacto</div>
                <div id="faq" className="menu-item" onClick={onMenuClick}>Preguntas frecuentes</div>
                <div id="recomendaciones" className="menu-item" onClick={onMenuClick}>Recomendaciones</div>
            </Menu>
            <img src="./images/logo.png" />
        </div>
    );
};

export default Header;


